<template>
  <Autocomplete
    v-model="currentValue"
    :placeholder="placeholder"
    :throttle="600"
    :max-results="6"
    :options="list"
    :pending="isPending"
    :no-results="noResults"
    :errors="combinedErrors"
    :disabled="disabled"
    :size="size"
    :theme="theme"
    :with-chevron="withChevron"
    v-bind="$attrs"
    @onSelect="handleSelect"
    @onSearch="handleSearch">
    <template #option="{ item }">
      <div>
        <span>{{ item.label }}</span>
        <div class="text-size-s">{{ item.address }}</div>
      </div>
    </template>
    <template #append="{ results, close }">
      <slot
        v-bind="{ results, close }"
        name="append"></slot>
    </template>
    <template #helper>
      <slot name="helper">Начните вводить название, минимум 3 символа</slot>
    </template>
    <template #no-result
      >Мы не нашли это учреждение в нашем справочнике, пожалуйста, убедитесь в
      правильности написания, или укажите значение в свободной форме</template
    >
  </Autocomplete>
</template>

<script>
import Autocomplete from "./Autocomplete";
import { talentRequest } from "@/services/api";
export default {
  name: "EducationAutocomplete",
  components: {
    Autocomplete,
  },
  inheritAttrs: false,
  props: {
    value: {},
    errors: {},
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
    theme: {
      type: String,
    },
    withChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: this.value,
      list: [],
      isPending: false,
      noResults: false,
      searchErrors: [],
      lastSearchValue: "",
    };
  },
  computed: {
    combinedErrors() {
      const { errors, searchErrors } = this;
      if (searchErrors.length) return searchErrors;
      return errors;
    },
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
  },
  methods: {
    handleSelect(data) {
      if (data && typeof data === "object" && data.value) {
        this.$emit("input", {
          title: data.value,
          dadata_hid: data.data.hid,
          region_with_type: data.data?.address?.data?.region_with_type,
          federal_district: data.data?.address?.data?.federal_district,
        });
        return;
      }
      this.$emit("input", "");
    },
    async handleSearch(query) {
      this.lastSearchValue = query;
      this.isPending = true;
      this.searchErrors = [];
      this.$emit("input", {
        title: this.currentValue,
        dadata_hid: null,
      });
      try {
        const response = await talentRequest({
          method: "POST",
          url: "/api/suggests/party/",
          params: {
            type: "education",
          },
          data: { query },
        });

        const list = response.data.map((n) => {
          const address = n?.data?.address?.unrestricted_value;
          return {
            label: n.unrestricted_value,
            value: n.value,
            data: n.data,
            address: address,
          };
        });
        this.list = list;
        this.noResults = list.length === 0;
      } catch (error) {
        this.searchErrors = [
          "Произошла ошибка поиска организации. Пожалуйста, сообщите нам об этой ошибке.",
        ];
      }
      this.isPending = false;
    },
  },
};
</script>
