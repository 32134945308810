<template>
  <div class="consent-notice">
    <div v-if="pending">
      <BaseSpinner
        brand
        class="mb-m" />
      <p>Подождите, мы проверяем файл...</p>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-8 col-xs-12 col-sm-12">
          <p v-if="isRejecetedConsent">
            Согласие на&nbsp;обработку твоих персональных данных было отклонено
            модератором. Без согласия зарегистрироваться на&nbsp;олимпиаду
            не&nbsp;получится. Попробуй загрузить согласие ещё раз! Есть вопрос?
            Напиши на&nbsp;<a
              class="link"
              :href="`mailto:${$store.state.supportEmail}?subject=%D0%9C%D0%BE%D0%B9%20%D1%84%D0%B0%D0%B9%D0%BB%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D1%8F%20%D0%B1%D1%8B%D0%BB%20%D0%BE%D1%82%D0%BA%D0%BB%D0%BE%D0%BD%D0%B5%D0%BD`"
              >{{ $store.state.supportEmail }}</a
            >
          </p>
          <p v-else>
            Необходимо загрузить согласие на&nbsp;обработку твоих персональных
            данных.<br />
            Есть вопрос? Напиши на&nbsp;<a
              class="link"
              :href="`mailto:${$store.state.supportEmail}?subject=%D0%9C%D0%BE%D0%B9%20%D1%84%D0%B0%D0%B9%D0%BB%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D1%8F%20%D0%B1%D1%8B%D0%BB%20%D0%BE%D1%82%D0%BA%D0%BB%D0%BE%D0%BD%D0%B5%D0%BD`"
              >{{ $store.state.supportEmail }}</a
            >
          </p>
          <p v-if="talentUser.adult_confirm_comment">
            <span>Комментарий модератора:</span>
            {{ talentUser.adult_confirm_comment }}
          </p>
        </div>
      </div>

      <div
        v-if="fileError"
        class="form-error">
        {{ fileError }}
      </div>
      <div
        v-if="consentError"
        class="form-error">
        {{ consentError }}
      </div>
      <div class="mt-l">
        <ul class="consent-steps">
          <li class="consent-steps__item">
            <BaseButton
              target="_blank"
              rel="noopener noreferrer"
              tag="a"
              theme="reg"
              download="%D1%84%D0%B0%D0%B9%D0%BB%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D1%8F.pdf"
              :href="fileURL"
              >Скачай шаблон</BaseButton
            >
          </li>
          <li class="consent-steps__item">
            Заполни документ с&nbsp;родителями или ответственными лицами
          </li>

          <li class="consent-steps__item">
            <BaseButton
              tag="label"
              for="consentFileInput"
              >Загрузи документ</BaseButton
            >
          </li>
        </ul>
        <p class="mt-s">
          <a
            href="https://talent.kruzhok.org/faq?category=personal_data"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >Зачем нам нужен этот файл?</a
          >
        </p>
        <div class="file-wrapper">
          <input
            id="consentFileInput"
            type="file"
            name="consentFile"
            accept="image/jpeg,image/png,application/pdf"
            @change="handleFileChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const STATUS_POLING_TIMEOUT = 3000;
const ENSURE_STATUS_DELAY = 2500;
const MAX_REPEAT_COUNT = 30;
const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
import {
  UNDERAGE_CONSENT_FILE_JUNIOR,
  UNDERAGE_CONSENT_FILE,
  ADULT_CONFIRM_MODERTION,
  ADULT_CONFIRM_APPROVED,
  ADULT_CONFIRM_REJECTED,
} from "@/constants";

import { talentRequest } from "@/services/api";
import { delay } from "@/utils";
export default {
  name: "ConsentNotice",
  data() {
    return {
      pending: false,
      timeout: null,
      fileError: "",
      repeatCount: 0,
      consentError: "",
      rejected: false,
    };
  },
  computed: {
    age() {
      return this.$store.getters["user/age"];
    },
    talentUser() {
      return this.$store.state.user.talentUser;
    },
    fileURL() {
      const { age } = this;
      if (age >= 14) return UNDERAGE_CONSENT_FILE;
      return UNDERAGE_CONSENT_FILE_JUNIOR;
    },
    isRejecetedConsent() {
      const { talentUser, rejected } = this;
      const isRejected =
        talentUser?.adult_confirmed === "" && talentUser?.adult_confirm_comment;
      return rejected || isRejected;
    },
  },
  beforeDestroy() {
    this.stopPoling();
  },
  methods: {
    async handleFileChange(event) {
      const { talentUser } = this;
      const file = event.target.files[0];
      if (!file || !talentUser) return;

      if (file.size > MAX_FILE_SIZE_BYTES) {
        this.fileError = `Максимальный размер файла ${MAX_FILE_SIZE_MB} MB`;
        return;
      }
      this.fileError = "";
      const fd = new FormData();
      fd.append("documents[0]", file);
      try {
        await talentRequest({
          url: `/api/users/${talentUser.id}/adult-confirms/`,
          method: "POST",
          data: fd,
        });
        this.polingStatus();
      } catch (error) {
        this.fileError = error.message;
      }
    },
    stopPoling() {
      clearTimeout(this.timeout);
      this.pending = false;
    },
    async polingStatus() {
      this.pending = true;
      // Если превышено количество попыток, считаем,
      // что файл на модерации
      if (this.repeatCount >= MAX_REPEAT_COUNT) {
        this.stopPoling();
        this.$store.commit("user/PATCH_STATE", {
          key: "talentUser",
          value: {
            adult_confirmed: ADULT_CONFIRM_MODERTION,
          },
        });
        return;
      }
      try {
        this.repeatCount += 1;
        this.consentError = "";
        const { data } = await talentRequest({
          url: `/api/users/${this.talentUser.id}/adult-confirms/latest/`,
          method: "GET",
        });
        if (data.status === ADULT_CONFIRM_APPROVED) {
          // Если файл подтвержден, останавливаем полинг
          // с небольшой задержкой, чтобы сигнал о новом статусе
          // успел дойти до бекенда ОНТИ
          await delay(ENSURE_STATUS_DELAY);
          this.stopPoling();
          this.rejected = false;
          this.$store.commit("user/PATCH_STATE", {
            key: "talentUser",
            value: {
              adult_confirmed: data.status,
            },
          });
          this.$store.commit("user/SET_STATE", {
            key: "consentCheckSuccess",
            value: true,
          });
          return;
        } else if (data.status === ADULT_CONFIRM_REJECTED) {
          // Если файл отклонен, оставливаем полинг, выводим ошибку
          this.stopPoling();
          this.rejected = true;
          this.$store.commit("user/PATCH_STATE", {
            key: "talentUser",
            value: {
              adult_confirmed: "",
            },
          });
          return;
        } else if (
          !data.status &&
          (data.recognition_percent_filled > 0 || data.recognition_score > 0)
        ) {
          // Если файл согласия был обработан распознавалкой
          // но при этом, она не выставила статус - то считает
          // такой файл в статусе На модерации, останавливаем полинг
          // с небольшой задержкой, чтобы сигнал о новом статусе
          // успел дойти до бекенда ОНТИ
          await delay(ENSURE_STATUS_DELAY);
          this.stopPoling();
          this.rejected = false;
          this.$store.commit("user/PATCH_STATE", {
            key: "talentUser",
            value: {
              adult_confirmed: ADULT_CONFIRM_MODERTION,
            },
          });
          return;
        }
        this.timeout = setTimeout(this.polingStatus, STATUS_POLING_TIMEOUT);
      } catch (error) {
        this.consentError = error.message;
        this.stopPoling();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.file-wrapper {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  left: -9999px;
}
.consent-steps {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  .fz(14);
  counter-reset: list;

  & ::v-deep .base-btn {
    min-width: 180px;
  }

  &__item {
    padding-left: 3.2em;
    max-width: 270px;
    margin-right: 20px;
    counter-increment: list;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 1em;
    margin-bottom: 1em;

    &::before {
      content: counter(list) ".";
      display: block;
      font-size: 3.2em;
      position: absolute;
      left: 0;
      font-weight: bold;
      top: 50%;
      line-height: 1;
      transform: translate(0, -50%);
      opacity: 0.5;
    }
  }

  @media screen and (max-width: @screen-md) {
    display: block;

    &__item {
      margin-bottom: 3em;
    }
  }
}
.wait-moderation {
  display: flex;
  flex-direction: row;
  &__img {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 20px;
  }
}
</style>
